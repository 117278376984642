.page-skeleton-w-navbar-footer-container {
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.page-skeleton-w-navbar-footer-navbar-bg-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(185deg, rgb(59, 48, 39) 0.00%,rgb(51, 51, 51) 100.00%);
}
.page-skeleton-w-navbar-footer-root-class-name {
  top: 0px;
  left: 0px;
  height: 100%;
  position: static;
}
.page-skeleton-w-navbar-footer-root-class-name1 {
  top: 0px;
  left: 0px;
  height: 100%;
  position: static;
}
.page-skeleton-w-navbar-footer-root-class-name2 {
  top: 0px;
  left: 0px;
  height: 100%;
  position: static;
}
.page-skeleton-w-navbar-footer-root-class-name3 {
  top: 0px;
  left: 0px;
  height: 100%;
  position: static;
}
.page-skeleton-w-navbar-footer-root-class-name4 {
  top: 0px;
  left: 0px;
  height: 100%;
  position: static;
}
.page-skeleton-w-navbar-footer-root-class-name5 {
  top: 0px;
  left: 0px;
  height: 100%;
  position: static;
}
@media(max-width: 600px) {
  .page-skeleton-w-navbar-footer-navbar-bg-container {
    background-color: #484848;
  }
}
