/* ### Root variables ### */
:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-space-space-unit: 8px;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-color-gray-darkgrey: #484848ff;
  --dl-radius-radius-round: 50%;
  --dl-size-size-maxcontent: 1414px;
  --dl-space-space-halfunit: 4px;
  --dl-space-space-tenunits: 80px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 40px;
  --dl-space-space-fourunits: 32px;
  --dl-color-dimcolors-dimred: #c06869ff;
  --dl-color-primary-lightred: #e8c0c0ff;
  --dl-radius-radius-radius24: 24px;
  --dl-space-space-doubleunit: 16px;
  --dl-space-space-tripleunit: 24px;
  --dl-color-dimcolors-dimblue: #2c467aff;
  --dl-color-secondary-dimblue: #2c467aff;
  --dl-color-dimcolors-dimgreen: #408878ff;
  --dl-color-primary-redprimary: #cd4e47ff;
  --dl-radius-radius-largeradius: 40px;
  --dl-radius-radius-mediumradius: 20px;
  --dl-color-secondary-dark-ochre: rgb(117, 97, 32);
  --dl-color-secondary-darker-ochre: #614f11ff;
  --dl-color-secondary-darker-ochre-brightened: #735c15ff;
}

/* Font imports */
@font-face {
  font-family: 'Proxima Soft';
  src: url('/ProximaSoft-Regular.woff2') format('woff2'),
      url('/ProximaSoft-Regular.woff') format('woff'),
      url('/ProximaSoft-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Soft';
  src: url('/ProximaSoft-Medium.woff2') format('woff2'),
      url('/ProximaSoft-Medium.woff') format('woff'),
      url('/ProximaSoft-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Soft';
  src: url('/ProximaSoft-SemiBold.woff2') format('woff2'),
      url('/ProximaSoft-SemiBold.woff') format('woff'),
      url('/ProximaSoft-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


.teleport-show {
  display: flex !important;
  transform: none !important;
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  font-family: Raleway;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  font-family: Raleway;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  font-family: Raleway;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.author-reference {
  font: italic;
}
.navbar-button {
  color: var(--dl-color-gray-white);
  border: none;
  background: none;
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  margin-left: var(--dl-space-space-unit);
  white-space: nowrap;
  margin-right: var(--dl-space-space-unit);
}
.navbar-button:active {
  color: var(--dl-color-gray-900);
}
.navbar-button:hover {
  transform: translateY(-5px);
  text-decoration: underline;
}
.Logo-Text {
  color: rgb(0, 0, 0);
  width: fit-content;
  height: fit-content;
  align-self: center;
  margin-top: auto;
  font-family: 'Proxima Soft';
  margin-left: var(--dl-space-space-tripleunit);
  white-space: nowrap;
  margin-right: var(--dl-space-space-tripleunit);
  margin-bottom: auto;
  font-style: normal;
  font-weight: 600;
}
.Section-Text {
  font-size: 18px;
  font-family: Raleway;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.SmallCard-Heading {
  font-size: 18px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Card-Text {
  font-size: 16px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.BigCard-Heading {
  font-size: 30px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Card-Heading {
  font-size: 24px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Anchor {
  font-size: 18px;
  font-family: Raleway;
  font-weight: 600;
  line-height: 1.55;
  text-decoration: none;
}
.Button-Text {
  font-size: 20px;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Section-Text-Lg {
  font-size: 20px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Section-Heading {
  font-size: 48px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 1.22;
  text-transform: none;
  text-decoration: none;
}



