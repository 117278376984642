.calendar-component-container {
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  padding: var(--dl-space-space-tripleunit);
}
.calendar-component-calendar-item {
  width: 100%;
  min-height: 247px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 70;
  align-items: stretch;
  border-radius: var(--dl-radius-radius-largeradius);
  flex-direction: row;
  background-color: var(--dl-color-secondary-dimblue);
}
.calendar-component-container1 {
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.calendar-component-container2 {
  width: 200px;
  height: 100%;
  display: flex;
  z-index: 100;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.calendar-component-icon {
  fill: var(--dl-color-primary-lightred);
  width: 100%;
  height: 100%;
  margin: var(--dl-space-space-tripleunit);
}
.calendar-component-container3 {
  top: 100px;
  bottom: 0px;
  height: 80px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.calendar-component-text {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: fit-content;
  font-size: 40px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
}
.calendar-component-text1 {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: fit-content;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}
.calendar-component-image {
  width: 200px;
  height: 100%;
  display: none;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-right-radius: var(--dl-radius-radius-largeradius);
  border-bottom-right-radius: var(--dl-radius-radius-largeradius);
}
.calendar-component-container4 {
  flex: 0 0 auto;
  width: 3px;
  display: flex;
  flex-direction: column;
  background-color: var(--dl-color-primary-lightred);
}
.calendar-component-container5 {
  flex: 1;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.calendar-component-text2 {
  color: var(--dl-color-gray-white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 230px;
  max-width: 100%;
}
.calendar-component-text3 {
  color: var(--dl-color-gray-white);
  display: inline-block;
  overflow: hidden;
  text-align: justify;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  white-space: pre-wrap;
}
.calendar-component-image1 {
  width: 200px;
  max-height:400px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-right-radius: var(--dl-radius-radius-largeradius);
  border-bottom-right-radius: var(--dl-radius-radius-largeradius);
}

@media(max-width: 940px) {
  .calendar-component-calendar-item {
    height: fit-content;
    align-items: center;
    flex-direction: column;
  }
  .calendar-component-container1 {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    position: relative;
    align-self: center;
    align-items: flex-start;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .calendar-component-container3 {
    top: 45px;
    left: auto;
    width: 119px;
    bottom: 0px;
    height: 104px;
    margin: auto;
    justify-content: center;
  }
  .calendar-component-image {
    height: 200px;
    display: flex;
    align-self: center;
    align-items: center;
    border-radius: var(--dl-radius-radius-largeradius);
    justify-content: center;
  }
  .calendar-component-container4 {
    width: 90%;
    height: 3px;
    align-self: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .calendar-component-container5 {
    width: 90%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .calendar-component-text3 {
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .calendar-component-image1 {
    display: none;
  }
}
@media(max-width: 600px) {
  .calendar-component-container {
    justify-content: center;
  }
  .calendar-component-calendar-item {
    flex-direction: column;
  }
  .calendar-component-container1 {
    gap: 0;
    height: fit-content;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .calendar-component-container2 {
    width: 178px;
    height: 100%;
    align-self: center;
    margin-left: 0px;
  }
  .calendar-component-icon {
    margin: 0px;
    align-self: center;
  }
  .calendar-component-image {
    width: 80%;
    height: fit-content;
    margin-top: var(--dl-space-space-unit);
    aspect-ratio: 16/9;
    margin-bottom: var(--dl-space-space-unit);
  }
  .calendar-component-container4 {
    width: 80%;
    margin-top: var(--dl-space-space-doubleunit);
  }
  .calendar-component-container5 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .calendar-component-text2 {
    width: 100%;
  }
  .calendar-component-image1 {
    width: fit-content;
    height: 200px;
    display: none;
    max-width: 100%;
    align-self: center;
    border-radius: var(--dl-radius-radius-largeradius);
  }
}
