.aankomende-optredens-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.aankomende-optredens-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.aankomende-optredens-container02 {
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.aankomende-optredens-calendar-item {
  width: 100%;
  height: 247px;
  margin: var(--dl-space-space-tripleunit);
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 70;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-largeradius);
  flex-direction: row;
  background-color: var(--dl-color-dimcolors-dimgreen);
}
.aankomende-optredens-container03 {
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.aankomende-optredens-container04 {
  width: 200px;
  height: 100%;
  display: flex;
  z-index: 100;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.aankomende-optredens-icon {
  fill: var(--dl-color-primary-lightred);
  width: 100%;
  height: 100%;
  margin: var(--dl-space-space-tripleunit);
}
.aankomende-optredens-container05 {
  top: 85px;
  left: 50px;
  width: 100px;
  height: 80px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.aankomende-optredens-text {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: fit-content;
  font-size: 40px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
}
.aankomende-optredens-text1 {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: fit-content;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}
.aankomende-optredens-image {
  width: 200px;
  height: 100%;
  display: none;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-right-radius: var(--dl-radius-radius-largeradius);
  border-bottom-right-radius: var(--dl-radius-radius-largeradius);
}
.aankomende-optredens-container06 {
  flex: 0 0 auto;
  width: 3px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--dl-color-primary-lightred);
}
.aankomende-optredens-container07 {
  flex: 1;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.aankomende-optredens-text2 {
  color: var(--dl-color-gray-white);
  overflow-wrap: break-word;
}
.aankomende-optredens-text3 {
  color: var(--dl-color-gray-white);
  display: -webkit-box;
  overflow: hidden;
  text-align: justify;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}
.aankomende-optredens-image1 {
  width: 200px;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-right-radius: var(--dl-radius-radius-largeradius);
  border-bottom-right-radius: var(--dl-radius-radius-largeradius);
}
.aankomende-optredens-container08 {
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.aankomende-optredens-calendar-item1 {
  width: 100%;
  height: 247px;
  margin: var(--dl-space-space-tripleunit);
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 70;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-largeradius);
  flex-direction: row;
  background-color: var(--dl-color-dimcolors-dimred);
}
.aankomende-optredens-container09 {
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.aankomende-optredens-container10 {
  width: 200px;
  height: 100%;
  display: flex;
  z-index: 100;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.aankomende-optredens-icon2 {
  fill: var(--dl-color-primary-lightred);
  width: 100%;
  height: 100%;
  margin: var(--dl-space-space-tripleunit);
}
.aankomende-optredens-container11 {
  top: 85px;
  left: 50px;
  width: 100px;
  height: 80px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.aankomende-optredens-text4 {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: fit-content;
  font-size: 40px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
}
.aankomende-optredens-text5 {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: fit-content;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}
.aankomende-optredens-image2 {
  width: 200px;
  height: 100%;
  display: none;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-right-radius: var(--dl-radius-radius-largeradius);
  border-bottom-right-radius: var(--dl-radius-radius-largeradius);
}
.aankomende-optredens-container12 {
  flex: 0 0 auto;
  width: 3px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--dl-color-primary-lightred);
}
.aankomende-optredens-container13 {
  flex: 1;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.aankomende-optredens-text6 {
  color: var(--dl-color-gray-white);
  overflow-wrap: break-word;
}
.aankomende-optredens-text7 {
  color: var(--dl-color-gray-white);
  display: -webkit-box;
  overflow: hidden;
  text-align: justify;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}
.aankomende-optredens-image3 {
  width: 200px;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-right-radius: var(--dl-radius-radius-largeradius);
  border-bottom-right-radius: var(--dl-radius-radius-largeradius);
}
.aankomende-optredens-container14 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.aankomende-optredens-container15 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.slanted-text {
  height: 400px;
  width: 100%;
  font-size: 50px;
  font-weight: bold;
  color: lightgray;
  transform: rotate(20deg);
  display: grid;
  place-content: center;
}
@media(max-width: 1400px) {
  .aankomende-optredens-text {
    color: var(--dl-color-gray-white);
    font-size: 40px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1;
    text-transform: none;
    text-decoration: none;
  }
  .aankomende-optredens-text4 {
    color: var(--dl-color-gray-white);
    font-size: 40px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 1;
    text-transform: none;
    text-decoration: none;
  }
}
@media(max-width: 1200px) {
  .aankomende-optredens-icon {
    width: 226px;
    height: 213px;
  }
  .aankomende-optredens-icon2 {
    width: 226px;
    height: 213px;
  }
}
@media(max-width: 991px) {
  .slanted-text {
    font-size: 32px;
  }
}
@media(max-width: 767px) {
  .aankomende-optredens-calendar-item {
    height: fit-content;
    align-items: center;
    flex-direction: column;
  }
  .aankomende-optredens-container03 {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    position: relative;
    align-self: center;
    align-items: flex-start;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .aankomende-optredens-container05 {
    top: 45px;
    left: auto;
    width: 119px;
    bottom: 0px;
    height: 104px;
    margin: auto;
    justify-content: center;
  }
  .aankomende-optredens-image {
    height: 200px;
    display: flex;
    align-self: center;
    align-items: center;
    border-radius: var(--dl-radius-radius-largeradius);
    justify-content: center;
  }
  .aankomende-optredens-container06 {
    width: 80%;
    height: 3px;
    align-self: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .aankomende-optredens-container07 {
    width: 80%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .aankomende-optredens-text3 {
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .aankomende-optredens-image1 {
    display: none;
  }
  .aankomende-optredens-calendar-item1 {
    height: fit-content;
    align-items: center;
    flex-direction: column;
  }
  .aankomende-optredens-container09 {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    position: relative;
    align-self: center;
    align-items: flex-start;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .aankomende-optredens-container11 {
    top: 45px;
    left: auto;
    width: 119px;
    bottom: 0px;
    height: 104px;
    margin: auto;
    justify-content: center;
  }
  .aankomende-optredens-image2 {
    height: 200px;
    display: flex;
    align-self: center;
    align-items: center;
    border-radius: var(--dl-radius-radius-largeradius);
    justify-content: center;
  }
  .aankomende-optredens-container12 {
    width: 80%;
    height: 3px;
    align-self: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .aankomende-optredens-container13 {
    width: 80%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .aankomende-optredens-text7 {
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .aankomende-optredens-image3 {
    display: none;
  }
}
@media(max-width: 600px) {
  .aankomende-optredens-container02 {
    justify-content: center;
  }
  .aankomende-optredens-calendar-item {
    flex-direction: column;
  }
  .aankomende-optredens-container03 {
    gap: 0;
    height: fit-content;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .aankomende-optredens-container04 {
    width: 178px;
    height: 100%;
    align-self: center;
    margin-left: 0px;
  }
  .aankomende-optredens-icon {
    margin: 0px;
    align-self: center;
  }
  .aankomende-optredens-image {
    width: 80%;
    height: fit-content;
    margin-top: var(--dl-space-space-unit);
    aspect-ratio: 16/9;
    margin-bottom: var(--dl-space-space-unit);
  }
  .aankomende-optredens-container06 {
    width: 80%;
    margin-top: var(--dl-space-space-doubleunit);
  }
  .aankomende-optredens-container07 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .aankomende-optredens-text2 {
    width: 100%;
  }
  .aankomende-optredens-image1 {
    width: fit-content;
    height: 200px;
    display: none;
    max-width: 100%;
    align-self: center;
    border-radius: var(--dl-radius-radius-largeradius);
  }
  .aankomende-optredens-container08 {
    justify-content: center;
  }
  .aankomende-optredens-calendar-item1 {
    flex-direction: column;
  }
  .aankomende-optredens-container09 {
    gap: 0;
    height: fit-content;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .aankomende-optredens-container10 {
    width: 178px;
    height: 100%;
    align-self: center;
    margin-left: 0px;
  }
  .aankomende-optredens-icon2 {
    margin: 0px;
    align-self: center;
  }
  .aankomende-optredens-image2 {
    width: 80%;
    height: fit-content;
    margin-top: var(--dl-space-space-unit);
    aspect-ratio: 16/9;
    margin-bottom: var(--dl-space-space-unit);
  }
  .aankomende-optredens-container12 {
    width: 80%;
    margin-top: var(--dl-space-space-doubleunit);
  }
  .aankomende-optredens-container13 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .aankomende-optredens-text6 {
    width: 100%;
  }
  .aankomende-optredens-image3 {
    width: fit-content;
    height: 200px;
    display: none;
    max-width: 100%;
    align-self: center;
    border-radius: var(--dl-radius-radius-largeradius);
  }
  .aankomende-optredens-container14 {
    justify-content: center;
  }
  .aankomende-optredens-container15 {
    justify-content: center;
  }
  .slanted-text {
    font-size: 24px;
  }
}
