.lid-worden-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.lid-worden-container1 {
  width: 100%;
  max-width: var(--dl-size-size-maxcontent);
}
.lid-worden-contactformulier {
  flex: 0 0 auto;
  width: var(--dl-size-size-maxcontent);
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.lid-worden-get-in-touch {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.lid-worden-content-container {
  width: 100%;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.lid-worden-form-container {
  width: 40%;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-tenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: var(--dl-color-gray-900);
}
.lid-worden-heading {
  margin-bottom: var(--dl-space-space-fourunits);
}
.lid-worden-name {
  width: 100%;
  border: none;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-tripleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.lid-worden-email {
  width: 100%;
  border: none;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-tripleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.lid-worden-message {
  width: 100%;
  border: none;
  height: 140px;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-tripleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.lid-worden-cta-btn {
  color: var(--dl-color-gray-white);
  text-align: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: var(--dl-radius-radius-radius24);
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}
@media(max-width: 1200px) {
  .lid-worden-contactformulier {
    width: 100%;
    max-width: var(--dl-size-size-maxcontent);
  }
}
@media(max-width: 991px) {
  .lid-worden-form-container {
    width: 60%;
  }
}
@media(max-width: 767px) {
  .lid-worden-content-container {
    width: 100%;
    flex-direction: column;
  }
  .lid-worden-form-container {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 600px) {
  .lid-worden-content-container {
    padding-left: 0px;
  }
  .lid-worden-form-container {
    padding: var(--dl-space-space-tripleunit);
    margin-right: 0px;
  }
}
