.text-blockimg-container {
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
.text-blockimg-container1 {
  width: 100%;
  height: fit-content;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.text-blockimg-block {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-largeradius);
  justify-content: center;
  background-color: var(--dl-color-primary-lightred);
}
.text-blockimg-content-container {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.text-blockimg-text {
  align-self: flex-start;
  text-align: center;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.text-blockimg-text1 {
  width: 100%;
  height: 100%;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.text-blockimg-image {
  width: 300px;
  height: auto;
  align-self: center;
  object-fit: cover;
  /* aspect-ratio: 1; */
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-mediumradius);
}
@media(max-width: 991px) {
  .text-blockimg-container1 {
    padding-left: 0px;
  }
  .text-blockimg-block {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
@media(max-width: 600px) {
  .text-blockimg-container {
    flex-direction: column;
  }
  .text-blockimg-image {
    align-self: flex-start;
    margin-left: var(--dl-space-space-tripleunit);
  }
}
