.section-header-container {
  display: flex;
  position: relative;
  justify-content: center;
}
.section-header-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}



@media(max-width: 767px) {
  .section-header-text {
    font-size: 24px;
    font-weight: 600;
  }
}
@media(max-width: 600px) {
  .section-header-container {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
}
