.text-block-container {
  width: 100%;
  height: fit-content;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.text-block-block {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-largeradius);
  justify-content: center;
  background-color: var(--dl-color-primary-lightred);
}
.text-block-content-container {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.text-block-text {
  align-self: flex-start;
  text-align: center;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.text-block-text1 {
  width: 100%;
  height: 100%;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.text-block-root-class-name {
  width: 100%;
}

.text-block-root-class-name2 {
  margin-bottom: var(--dl-space-space-tenunits);
}
